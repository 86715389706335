<template>
  <div>
    <h1 class="font-medium text-3xl mb-3">{{ $t("statistics") }}</h1>
    <div class="mb-3">
      <div class="flex flex-wrap -m-2">
        <div class="p-1 xl:p-2 w-1/2 xl:w-1/4">
          <div
            class="bg-gradient-to-br from-blue-200 to-blue-500 rounded p-3 h-full flex flex-col justify-between"
          >
            <p class="text-lg 2xl:text-xl font-medium">
              {{ $t("trains_count") }}
            </p>
            <p class="text-2xl mt-1 xl:mt-3 font-medium">
              {{ cardData.trains_count }}
            </p>
          </div>
        </div>
        <div class="p-1 xl:p-2 w-1/2 xl:w-1/4">
          <div
            class="bg-gradient-to-br from-green-300 to-blue-400 rounded p-3 h-full flex flex-col justify-between"
          >
            <p class="text-lg 2xl:text-xl font-medium">
              {{ $t("inspections_count") }}
            </p>
            <p class="text-2xl mt-1 xl:mt-3 font-medium">
              {{ cardData.inspections_count }}
            </p>
          </div>
        </div>
        <div class="p-1 xl:p-2 w-1/2 xl:w-1/4">
          <div
            class="bg-gradient-to-br from-yellow-300 to-fe4b62 rounded p-3 h-full flex flex-col justify-between"
          >
            <p class="text-lg 2xl:text-xl font-medium">
              {{ $t("repairs_count") }}
            </p>
            <p class="text-2xl mt-1 xl:mt-3 font-medium">
              {{ cardData.repairs_count }}
            </p>
          </div>
        </div>
        <div class="p-1 xl:p-2 w-1/2 xl:w-1/4">
          <div
            class="bg-gradient-to-br from-indigo-300 to-purple-500 rounded p-3 h-full flex flex-col justify-between"
          >
            <p class="text-lg 2xl:text-xl font-medium">
              {{ $t("spares_count") }}
            </p>
            <p class="text-2xl mt-1 xl:mt-3 font-medium">
              {{ cardData.spares_count }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap -m-1 mb-1">
      <div class="p-1 w-full 2xl:w-1/2 3xl:w-2/5">
        <div class="bg-white rounded border p-3">
          <h2 class="text-lg font-medium mb-2">
            {{ $t("dashboard_total_repairs_count") }}
          </h2>
          <div id="chart" v-if="series && series.length">
            <apexchart
              type="donut"
              width="450"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
        </div>
      </div>
      <div class="p-1 w-full 2xl:w-1/2 3xl:w-3/5">
        <div class="bg-white rounded border p-3 overflow-hidden">
          <h2 class="text-lg font-medium mb-2">{{ $t("train") }}</h2>
          <el-table :data="trainData" border style="width: 100%">
            <el-table-column prop="name" :label="$t('name')"> </el-table-column>
            <el-table-column
              prop="next_corrective_type"
              :label="$t('next_corrective_type')"
            >
            </el-table-column>
            <el-table-column :label="$t('done_samples_for_current_corrective')">
              <template slot-scope="scope">
                <p
                  v-for="(item, index) in scope.row
                    .done_samples_for_current_corrective"
                  :key="index"
                >
                  {{ item }},
                </p>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('not_done_samples_for_current_corrective')"
            >
              <template slot-scope="scope">
                <p
                  v-for="(item, index) in scope.row
                    .not_done_samples_for_current_corrective"
                  :key="index"
                >
                  {{ item.name }},
                </p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="-my-1">
      <div class="py-1" v-for="train in trainData" :key="train.id">
        <router-link :to="`/trains/${train.id}`" class="block hover:shadow-md hover:border-gray-300 bg-white rounded border p-3 overflow-hidden">
          <h3 class="text-lg font-medium">{{train.name}}</h3>
          <div class="w-full h-24">
            <img class="w-full h-full object-cover" src="@/assets/img/Talgo.png" alt="">
          </div>
        </router-link>
      </div>
    </div>
    <div class="bg-white mt-2 rounded border p-3 overflow-hidden">
      <h2 class="text-lg font-medium mb-2">{{ $t("logs") }}</h2>

      <el-table :data="dashboardData" border style="width: 100%">
        <el-table-column prop="log" :label="$t('log')" min-width="300">
        </el-table-column>
        <el-table-column prop="created_time" :label="$t('time')" min-width="200">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Dashboard",
  data() {
    return {
      cardData: "",
      dashboardData: [],
      series: [],
      trainData: [],
    };
  },
  mounted() {
    this.getDashboard();
    this.getTrains();
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "donut",
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
        },
        labels: [
          this.$t("NEED_SPARE"),
          this.$t("DONE"),
          this.$t("REPAIRING"),
          this.$t("POSTPONED"),
        ],

        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Rubik",
                  color: "#dfsda",
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  label: this.$t("total_repairs_count"),
                  color: "#373d3f",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
      };
    },
  },
  methods: {
    getDashboard() {
      axios.get("dashboard/").then((response) => {
        this.cardData = response.data;
        this.dashboardData = response.data.logs.map((data) => {
          return {
            ...data,
            created_time: data.created_time
              .split(".")
              .shift()
              .replace("T", " "),
          };
        });
        this.series.push(response.data.need_spare_repairs_count);
        this.series.push(response.data.done_repairs_count);
        this.series.push(response.data.repairing_repairs_count);
        this.series.push(response.data.postponed_repairs_count);
      });
    },

    async getTrains() {
      await axios.get(`trains/?no_page`).then((response) => {
        this.trainData = response.data;
      });
    },
  },
};
</script>

<style lang="scss">
.el-table .cell {
  word-break: normal !important;
}
// .train {
//   img {
//     position: relative;
//       animation: animateTrain 20s linear infinite;
//   }
// }
// @keyframes animateTrain {
//   0% {
//     left: 100%;
//   }
//   50% {
//     left: 0;
//   }
//   100% {
//     left: -100%;
//   }
// }
</style>
